.section__infoservicios {
    height: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 73%;
    background-color: #ffffff;
    margin-right: 10px;
    z-index: 80;
    padding-top: 6rem;
}


.div__infoservicios {
    width: 70%;

    height: auto;
    font-size: 1.2rem;
    margin: 0 auto;
}

.retorno__infoservicios {
    width: 100%;
    height: 4rem;
    margin-bottom: 1rem;
    display: flex;
    align-self: center;
    align-items: center;


}

.retorno__infoservicios a {
    text-decoration: none;
    color: #000000;
}

.retorno__infoservicios a:hover {
    color: blue;
}


.retorno__infoservicios a:first-child {
    margin-left: 20px;
}

.retorno__infoservicios span {
    color: #636161;
    font-weight: bold;
}

.retorno__infoservicios img {
    height: 24px;
    width: 24px;
    margin-right: 10px;
    display: flex;
    align-items: center;

    filter: brightness(1.2) saturate(10000%) invert(60%) sepia(100%) saturate(5836%) hue-rotate(90deg) brightness(93%) contrast(95%);
}







@media only screen and (max-width:1200px) {
    .section__infoservicios {
        padding-top: 0;
    }


    .div__infoservicios {
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
        width: 85%;
        min-width: 400px;
        background-color: #ffffff;
        margin-right: 10px;
        z-index: 80;
    }

    .retorno__infoservicios {

        height: 3rem;
        margin-bottom: 0;
    }
}


@media only screen and (max-width: 550px) {

    .section__infoservicios {
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        min-width: 100%;
        background-color: #ffffff;
        margin-right: 0px;
        z-index: 80;
        padding-top: 2rem;
    }


    .div__infoservicios {
        width: 100%;
        grid-template-columns: 1fr;
        justify-items: center;
        align-items: center;
        height: auto;
        margin: 0 auto;
        display: block;
        padding-bottom: 10vh;
    }


    .retorno__infoservicios {
        font-size: 14px;
    }

    .retorno__infoservicios img {
        height: auto;
        width: 3%;
        margin-left: 5px;
    }

}