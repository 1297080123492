.AYM__CONTRATISTAS__PRODUCTOS__CONTENEDORES {
    height: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 73%;
    background-color: #ffffff;
    margin-right: 10px;
    z-index: 80;
}


.AYM__CONTRATISTAS__SECTION__PRODUCTOS__CONTENEDORES {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
    height: auto;
    margin: 0 auto;
}

.banner__PRODUCTOS__CONTENEDORES {
    background-color: rgba(1, 87, 6, 0.968);
    background-image: url('../../../aym666/galery/baners/PORTADA-CONTENEDORES.webp');
    padding: 0;
    margin: 0;
    height: 45vh;
    width: 100%;

    background-blend-mode: soft-light;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;

}


.encabezado__PRODUCTOS__CONTENEDORES {
    padding-bottom: 3rem;
}

.encabezado__PRODUCTOS__CONTENEDORES__h3 {
    font-size: 2.1rem;
    text-align: center;
    padding-top: 2rem;
    font-weight: 900;
    font-family: 'Alatsi';
    color: rgb(17, 113, 3);
}

.PRODUCTOS__CONTENEDORES__P {
    font-size: 1.5rem;
    text-align: center;
    width: 85%;
    margin: 0 auto;
}


.div__comprar__contenedores {
    display: grid;
    place-items: center;
    padding-top: 0rem;
    padding-bottom: 25vh;
}

.btn__comprar__contenedores {
    background-color: #5ab22a;
    border: none;
    color: #fff;
    font-size: 1rem;
    padding: 1rem 1rem;
    border-radius: 15px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: bold;
    box-shadow: 0px 3px 6px rgba(90, 178, 42, 0.5);
}

.proyecto-img {
    cursor: pointer;
}



@media only screen and (max-width: 1000px) {

    .AYM__CONTRATISTAS__PRODUCTOS__CONTENEDORES {
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
        width: 85%;
        background-color: #ffffff;
        margin-right: 10px;
        z-index: 80;
    }



}


@media only screen and (max-width: 550px) {
    .AYM__CONTRATISTAS__PRODUCTOS__CONTENEDORES {
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        min-width: 100%;
        background-color: #ffffff;
        margin-right: 0px;
        margin: 0 auto;
        z-index: 80;
    }


    .banner__PRODUCTOS__CONTENEDORES {
        background-color: rgba(1, 87, 6, 0.968);
        background-image: url('../../../aym666/galery/baners/PORTADA-CONTENEDORES.webp');
        padding: 0;
        margin: 0;
        height: 45vh;
        width: 100%;

        background-blend-mode: soft-light;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 10% 50%;
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;

    }
}