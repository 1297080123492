.AYM__CONTRATISTAS__HOME {
    height: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 73%;
    background-color: #ffffff;
    margin-right: 10px;
    z-index: 80;



}



.AYM__CONTRATISTAS__CONTACTOBARRA span:first-child {
    justify-self: start;
    margin-left: 20px;
}

.AYM__CONTRATISTAS__CONTACTOBARRA span:last-child {
    justify-self: end;
    margin-right: 20px;
}


.AYM__CONTRATISTAS__SECTION__HOME,
.AYM__CONTRATISTAS__DIV__slide,
.AYM__CONTRATISTAS__SECTION__SERVICIOS,
.AYM__CONTRATISTAS__SECTION__CONOCENOS,
.AYM__CONTRATISTAS__SECTION__VIDEO,
.AYM__CONTRATISTAS__SECTION__DIAGNOSTICO,
.AYM__CONTRATISTAS__SECTION__CONTACTO {
    grid-column: 2;
    max-width: 100%;
    align-items: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;

}


.AYM__CONTRATISTAS__SECTION__HOME {
    background: linear-gradient(90deg, rgb(4, 141, 130) 0%, rgb(4, 141, 130) 80%, rgb(89, 206, 178) 100%);
    max-width: 90%;
    margin: 0 auto;
    height: 100vh;
}



.AYM__CONTRATISTAS__DIV__CONTENEDOR__HOME {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5vh;
}

.AYM__H1__CONTENEDOR__TITLE {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.AYM__H1__TITLE.line {
    font-size: 2.1rem;
    text-align: center;


    font-family: 'Alatsi';
    font-weight: 500;
    color: #ffffff;
    animation-name: DOWNTITLE;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    display: flex;

}



.AYM__H1__TITLE.resaltar.f {
    background: linear-gradient(-33deg, #a0f4a7, #4003e8, #2312ab, #0400ff, #ffffff, #69ff9b, #5cfb7c, #3fff66);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: cambio-color 21s ease infinite;
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 400% 400%;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.8;
    text-align: center;

}



.AYM__IMG__CONTENEDOR__TITLE {
    width: 40px;
    height: 20px;
}



.AYM__IMG__CONTENEDOR {
    padding-top: 2rem;
}


.AYM__IMG__LOGOAYM.principal {

    filter: drop-shadow(1px 13.666px 13.666px rgba(255, 255, 255, 0.4));
    animation-name: elevador;
    animation-duration: 3.69s;
    animation-iteration-count: infinite;
    width: 100px;
    height: 100px;
}

.AYM__IMG__ENGRANAJES__LOGOAYM {
    height: 60px;
    width: 60px;
}



.AYM__CONTRATISTAS__CONTACTOBARRA {

    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: start;
    align-items: start;
    grid-gap: 10px;
    width: 90%;
    padding-top: 5vh;
    right: 0;
    margin: 0 auto;
    white-space: nowrap;
    z-index: 90;
    font-size: 1.2rem;
    font-weight: bold;
    background: linear-gradient(90deg, rgb(4, 141, 130) 0%, rgb(4, 141, 130) 80%, rgb(89, 206, 178) 100%);
    right: 0;
    max-width: 90%;

}







.AYM__CONTRATISTAS__DIV__slide {

    grid-column: 2;
    max-width: 100%;
    align-items: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;


    padding: 0;
    margin: 0;

}

.contenedor__slider {
    grid-column: 1fr;
    background-color: burlywood;
    height: 90vh;
    width: 90%;
    max-width: 100%;
    border-radius: 0.6rem;
    margin: 0 auto;
    align-items: center;
    align-self: center;
}






.slider {
    display: grid;
    grid-column: 1 / -1;
    width: 100%;
    height: 90vh;
    padding: 0;
    margin: 0;
}





.slide {
    display: none;
    align-items: center;
    justify-content: center;
}

.fade {
    opacity: 1;
    animation: fade 0.666s linear;
}


.slide.active {
    display: grid;

}




.slide-content {
    text-align: center;
    width: 100%;
}

.slide-content h2 {
    font-family: 'Alatsi';
    font-style: normal;
    line-height: 1;
    margin: 0 auto;
    font-size: 3rem;
    color: white;
    width: 80%;
    text-shadow: 2px 2px 2px rgba(66, 66, 66, 0.666);
}

.slide-content p {

    font-style: normal;
    font-size: 1.5rem;
    line-height: 1.1;
    width: 80%;
    margin: 0 auto;
    font-weight: 600;
    text-align: center;
    padding: 1rem 0;
    color: #ffffff;
    text-shadow: 2px 2px 2px rgba(66, 66, 66, 0.666);
}


.slide-content a {
    font-size: 1rem;
    font-family: 'Georgia, serif';
}


.btn {
    background-color: var(--FONDO-Verde);
    color: var(--TEXTO-Azul);
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin-top: 10px;
    text-transform: none;
    transition: 0.666s;
    border-radius: var(--Border-radius);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);

}

.btn:hover {
    background-color: rgb(29, 1, 130);
    color: white;
    cursor: url('../cur/Mgold3.ico'), auto;
    transform: scale(1.03, 1.03);
}



.slide1 {
    background-image: url('../carrusel/A-Y-M-Contratistas-Gestión-Administrativa.webp');
    background-color: rgba(13, 1, 107, 0.733);
}

.slide2 {
    background-image: url('../carrusel/A-Y-M-Contratistas-Gestión-Ambiental.webp');
    background-color: rgba(3, 106, 6, 0.929);
}

.slide3,
.slide4,
.slide5 {
    background-color: rgba(4, 139, 128, 0.973);
}


.slide3 {
    background-image: url('../carrusel/A-Y-M-Contratistas-Construccion-y-proyectos.webp');
}

.slide4 {
    background-image: url('../carrusel/A-Y-M-Contratistas-Seguridad.webp');
}

.slide5 {
    background-image: url('../carrusel/A-Y-M-Contratistas-Mantenimiento-Industrial.webp');
}

.slider>.slide {
    background-blend-mode: soft-light;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 30%;
}


.slider__arrow__contenedor {
    position: relative;
}


.slider-arrow {
    position: absolute;
    top: -10vh;
    width: 35px;
    height: 35px;
    display: flex;
    background-color: transparent;
    border: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transform: translateY(-50%);
    cursor: url('../cur/Mblue3.ico'), auto;

}



.slider-arrow img {
    width: 66px;
    filter: brightness(0) saturate(100%) invert(18%) sepia(86%) saturate(5836%) hue-rotate(225deg) brightness(93%) contrast(95%);
    transition: transform 0.2s ease-in-out;
}


.slider-arrow img:hover {
    transform: scale(1.2);
    filter: brightness(0) saturate(600%) invert(11%) sepia(70%) saturate(5229%) hue-rotate(120deg) brightness(210%) contrast(92%);

}


.slider-arrow-left {
    left: 40px;
}

.slider-arrow-right {
    right: 40px;

}










.AYMH1 {
    line-height: 1.45;
    font-family: 'Alatsi';
    font-weight: 800;
    color: #fff;
    text-shadow: 1px 1px 1.5px rgb(66, 66, 66);
    font-size: 3.43666rem;
    margin-top: 0;
    margin-bottom: 1rem;
    display: block;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    text-align: center;
    margin-top: 6rem;
}

.AYMH1__P {
    display: block;
    text-align: center;
    line-height: 1.8;
    font-weight: 400;
    box-sizing: border-box;
    color: #ffffff;
    font-family: 'Inter', sans-serif;
    font-size: 1.866rem;
    padding-bottom: 2rem;
    width: 80%;
}

.AYMH1__span {
    line-height: 1.8;
    font-weight: 600;
    box-sizing: border-box;
    color: #28fbff;
    font-family: 'Inter', sans-serif;
    font-size: 1.866rem;
    text-align: center;
}





.goldP {
    display: block;
    text-align: center;
    line-height: 1.8;
    font-weight: 400;
    box-sizing: border-box;
    color: #ffffff;
    font-family: 'Inter', sans-serif;
    font-size: 1.366rem;
    padding-bottom: 2rem;
    width: 100%;
    text-align: center;
}

.golP__span {
    line-height: 1.8;
    font-weight: 600;
    box-sizing: border-box;
    color: #28fbff;
    font-family: 'Inter', sans-serif;
    font-size: 1.666rem;
    text-align: center;
}




.AYM__CONTRATISTAS__CONTENEDOR__TUERCAS {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-rows: auto;
    width: 100%;
    display: grid;
    padding-top: 2rem;
    gap: 20px;
}

.AYM__CONTRATISTAS__CONTENEDOR__TUERCAS .F4-a {
    display: grid;
    grid-template-rows: auto;
}

.goldP {
    width: 70%;
}





.F4-h3 {
    display: inline-block;
    background-color: var(--FONDO-Verde);
    color: var(--TEXTO-Azul);
    text-align: center;
    text-decoration: none;
    border-radius: var(--Border-radius);
    transition: all 0.6s ease-in-out;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    vertical-align: middle;
    font-size: 1.5rem;
    letter-spacing: 1px;
    height: auto;
    width: auto;
    padding: 10px;
}


.F4-h3:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
    background-color: #5beb5b;
    color: #252e69;

}

.F4-wrapper:hover .F4-img {
    transform: scale(1.0666);
    filter: drop-shadow(0px 0px 1px rgba(48, 2, 255, 0.324));
    animation-name: heartbeat;
    animation-duration: 1.666s;
    animation-iteration-count: infinite;
}



.AYM__CONTRATISTAS__SECTION__SERVICIOS {


    display: grid;
    grid-template-columns: 1fr;
    max-width: 90%;
    height: auto;
    align-items: center;
    justify-items: center;
    place-items: center;
    padding: 0;
    margin: 0 auto;

    background-color: #052b58;




}


.AYM__CONTRATISTAS__DIV__CONTENEDOR__SERVICIOS {


    background-color: #104764;
    display: grid;
    place-items: center;
    justify-content: left;
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 0;
    grid-column: 1 / -1;
    grid-template-columns: auto;

}

.AYM__CONTRATISTAS__CONTENEDOR__TUERCAS .F4-a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    gap: 20px;
}


.imgtuercas {
    height: 210px;
    width: auto;
    transition: transform 0.2s ease-in-out;
}

.aym-F4>div:nth-child(1):hover .imgtuercas {
    transform: scale(1.05);
}



.info-icon {
    width: 12px;
    height: 12px;
    margin-right: 12px;
    user-select: none;
    filter: invert(100%) sepia(100%) saturate(2000%) hue-rotate(90deg)brightness(80%);
}


.icon-roted {
    animation-name: rotate;
    animation: rotate 6s linear infinite;
    transform-origin: center;
    width: 23px;
    height: 23px;
}


.goldP:hover .icon-roted {
    filter: brightness(1.2) saturate(10000%) invert(60%) sepia(100%) saturate(5836%) hue-rotate(90deg) brightness(93%) contrast(95%);
}





.F4-a:hover .F4-img {
    transform: scale(1.0666);
    filter: drop-shadow(0px 0px 1px rgba(48, 2, 255, 0.324));
    animation-name: heartbeat;
    animation-duration: 2.666s;
    animation-iteration-count: infinite;
}



















.AYM__CONTRATISTAS__SECTION__CONOCENOS {

    display: grid;
    grid-template-columns: 1fr;
    max-width: 90%;
    height: auto;
    align-items: center;
    justify-items: center;
    place-items: center;
    padding: 0;
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #104764;
}

.bloqueaym {
    grid-column: 1fr;
    background-color: #052b58;
    height: auto;
    width: 90%;
    max-width: 1200px;
    border-radius: 0.6rem;
    margin: 0 auto;
    align-items: center;
    align-self: center;

}

.bloqueaym__divking {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    height: auto;

}

.bloqueaym__A,
.bloqueaym__B {
    grid-column: 1;
}



.BLOQUEAYM__SUBCONTENEDOR {
    display: grid;
    grid-template-rows: 10vh auto;
    grid-template-columns: 100%;
    grid-gap: 20px;
    border-radius: 0.6rem;
    background: linear-gradient(180deg, #03002d, #10335b);
    margin: 1rem;
    padding: 1rem;
}

.bloqueaym__IMG__contenedor {
    background-image: linear-gradient(15deg, #064a00, #092400), url('../recursos/PNG/FONDO__ENGRANAJE__01.webp');
    background-blend-mode: hard-light;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 10%;
    border-radius: 0.6rem 0.6rem 0rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: -1rem;
    margin: -1rem;
}


.bloqueaym__IMG__contenedor.VISION {
    background-image: linear-gradient(15deg, #064a00, #092400), url('../recursos/PNG/FONDO__ENGRANAJE__02.webp');
}





.BLOQUEAYM__H2,
.BLOQUEAYM__H3,
.BLOQUEAYM__P {
    padding: 1rem;
    color: #fff;
}

.BLOQUEAYM__H2 {
    text-align: center;
    font-size: 3rem;

}

.BLOQUEAYM__H3 {
    text-align: center;
    font-size: 3rem;
    display: flex;
    align-items: center;
    line-height: 1.2;
    filter: drop-shadow(0px 0px 2px rgba(37, 0, 202, 0.977));

}

.BLOQUEAYM__P.SUBTITLE__AYM {
    text-align: center;
    max-width: 60%;
    margin: 0 auto;
    font-size: 1.4rem;
    color: #fff;
}

.BLOQUEAYM__P {
    font-size: 1.5rem;
}

.BLOQUEAYM__AUDIO__ICON {
    height: 30px;
    width: 30px;
    margin-right: 10px;
    vertical-align: middle;
    animation-name: heartbeat;
    animation-duration: 1.666s;
    animation-iteration-count: infinite;
    filter: drop-shadow(0px 0px 4px rgba(219, 211, 255, 0.977));
}

.BLOQUEAYM__P__CONTENEDOR {
    overflow: auto;
}

.BLOQUEAYM__P__CONTENEDOR::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}




.BLOQUEAYM__P__CONTENEDOR::-webkit-scrollbar-thumb {
    background-color: rgb(29, 122, 1);
    border-radius: 5px;
}
















.AYM__CONTRATISTAS__SECTION__VIDEO,
.AYM__CONTRATISTAS__DIV__SERVICIOS {
    width: 100%;
    height: 100%;
}

.video-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.videoaym {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.F4-img.imgtuercas {
    width: 250px;
    height: 250px;
}





.AYM__CONTRATISTAS__SECTION__VIDEO {

    display: grid;
    grid-template-columns: 1fr;
    max-width: 90%;
    height: 60vh;
    align-items: center;
    justify-items: center;
    place-items: center;
    padding: 0;
    margin: 0 auto;
    background-color: #052b58;
}


.AYM__CONTRATISTAS__SECTION__CONTENEDOR {
    grid-column: 1fr;
    height: 320px;
    width: 90%;
    max-width: 600px;
    border-radius: 0.6rem;
    margin: 0 auto;
    align-items: center;
    align-self: center;
    display: grid;
    padding: 0.5rem;
}

.video-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;


}



.video-container video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    align-items: center;



}

.video-container .loading {

    position: absolute;
    width: 125px;
    height: 125px;
    right: 0;
    top: 10%;
    left: -20%;
    transform: translate(50%, 50%);



}

.video-container .hidden {
    display: none;
}



.loading {
    display: block;
    margin: auto;
}

.hidden {
    display: none;
}




.AYM__CONTRATISTAS__SECTION__DIAGNOSTICO {

    display: grid;
    grid-template-columns: 1fr;
    max-width: 90%;
    height: auto;
    align-items: center;
    justify-items: center;
    place-items: center;
    padding: 0;
    margin: 0 auto;
    background: #104764;
}

.AYM__CONTRATISTAS__SECTION__DIAGNOSTICO__CONTENEDOR {
    grid-column: 1fr;

    height: auto;
    width: 90%;
    max-width: 1200px;
    border-radius: 0.6rem;
    margin: 0 auto;
    align-items: center;
    align-self: center;
    justify-items: center;
    display: grid;
    padding: 0.5rem;
    grid-template-rows: auto auto auto;
}

.SECTION__DIAGNOSTICO__DIV1 {
    height: 70vh;
    background-image: linear-gradient(15deg, #01015c, #027a81), url('../recursos/JPG/FONDO__OFICINA__AYM.webp');
    background-blend-mode: hard-light;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 100%;
    border-radius: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;



}

.SECTION__DIAGNOSTICO__DIV1 h2 {
    font-size: 3.3rem;
    font-weight: 900;
    text-transform: uppercase;
    color: #fff;
    font-family: 'Alatsi';
    text-shadow: 1.2px 1.2px 1.2px rgb(10, 1, 63);
    margin: 0 auto;
    background-image: linear-gradient(to right, rgb(74, 193, 1) 66.666%, transparent 50%);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 80% 3px;
}



.SECTION__DIAGNOSTICO__DIV1 p {

    font-size: 2rem;
    margin: 0 auto;
    color: white;
    font-family: 'Alatsi';
    text-align: center;

}

.SECTION__DIAGNOSTICO__DIV2 {
    background-color: #052b58;
    border-radius: 15px;
    margin-top: 1rem;
}


.SECTION__DIAGNOSTICO__DIV2 h2 {
    font-size: 3rem;
}


.SECTION__DIAGNOSTICO__DIV2 p {
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center;
    margin: 0 auto;
}

.SECTION__DIAGNOSTICO__DIV3 h2 {
    font-size: 3rem;
    margin: 0 auto;
    color: white;
    font-family: 'Alatsi';
    text-align: center;
}











.theme-toggle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    justify-content: left;
    width: 66px;
    height: 66px;
    filter: drop-shadow(0 1px 1px rgba(234, 255, 0, 0.703));
    opacity: 0.5;

}

.theme-toggle-image {
    width: 33px;
    height: 33px;
    transition: transform 0.3s ease-in-out;
}

.theme-toggle-image.dark-mode {
    transform: rotate(180deg);
}






.AYM__CONTRATISTAS__SECTION__CONTACTO {

    display: grid;
    grid-template-columns: 1fr;
    max-width: 90%;
    height: auto;
    align-items: center;
    justify-items: center;
    place-items: center;
    padding: 0;
    margin: 0 auto;
    background: #104764 url('../recursos/JPG/FONDO__PERUAYM210X300.webp');
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 1%;
}


.AYM__CONTRATISTAS__SECTION__CONTACTO__CONTENEDOR {
    grid-column: 1fr;
    background-color: rgba(255, 255, 255, 0.788);
    height: auto;
    width: 90%;
    max-width: 600px;
    border-radius: 0.6rem;
    margin: 0 auto;
    align-items: center;
    align-self: center;
    justify-items: center;
    display: grid;
    padding: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}



.AYM__CONTRATISTAS__DIV__CONTACTO__CONTENEDOR {
    display: grid;
    place-items: center;
    width: 100%;

}





form {
    display: grid;
    grid-template-columns: 1fr;
    font-family: 'Alatsi';
    margin-bottom: 2rem;
    width: 100%;
    gap: 1rem;
    justify-items: center;
    align-items: center;
    background-color: transparent;
    border-radius: 0.5rem;
}

.formularioh2 {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    font-size: 3rem;
    text-align: center;
    color: #252e69;

}


.form-label {
    align-self: center;
    justify-self: left;
    font-size: 1rem;
    margin-left: 30px;

}

.caracteresp {
    align-self: center;
    justify-self: left;
    font-family: 'Open Sans', sans-serif;
    font-weight: lighter;
    color: var(--TEXTO-Azul);
    font-size: 0.9rem;
}


input,
textarea {
    width: 85%;
    margin: 0 auto;
    padding: 0.6rem;
    margin-bottom: 15px;
    margin-top: 0.3rem;
    border: 1px solid rgb(3, 3, 60);
    border-radius: 5px;
    box-shadow: 0 0 2px #2200ff;
    font-size: 1rem;
    padding-left: 1%;
    height: 20px;

}

input[type="submit"] {
    background-color: var(--FONDO-Verde);
    color: var(--TEXTO-Azul);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 15px;

}

input[type="submit"]:hover {
    background-color: #45a049;
}

input:focus,
textarea:focus {
    outline: none;
    box-shadow: 0 0 5px #219c02;
}

textarea.mensaje-textarea {
    height: 150px;
    resize: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    font-size: 0.8rem;
}

.caracteresp {
    margin-left: 10%;
    margin-bottom: 2rem;
}

.submitb {
    background-color: var(--FONDO-Verde);
    color: var(--TEXTO-Azul);
    width: 50%;
    border: none;
    border-radius: var(--Border-radius);
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.5s ease;
    align-self: center;
    justify-self: center;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    margin-bottom: 2rem;
}

.submitb:hover {
    background-color: #100698;
    cursor: url('../cur/Mgold3.ico'), auto;

}

.telefono-input.error {
    border: 2px solid red;
}

.AYM__CONTRATISTAS__SECTION__RUTAS {

    display: grid;
    grid-template-columns: 1fr;
    max-width: 90%;
    height: auto;
    align-items: left;
    left: 0;
    justify-items: left;
    place-items: center;
    padding: 0;
    margin: 0 auto;
    background: #ffffff;
    display: none;
}


.AYM__IMG__SUBCONTENEDOR {
    display: flex;
    height: auto;
    width: 100%;
    min-width: 100%;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 10vh;

}

.ISOIMG {
    height: auto;
    width: 80px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;

}

.footer__AYM__HOME {
    display: none;
}





@media only screen and (max-width: 1000px) {

    .AYM__CONTRATISTAS__HOME {
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
        width: 85%;
        min-width: 400px;
        background-color: #ffffff;
        margin-right: 10px;
        z-index: 80;
    }



    .ISOIMG {
        height: auto;
        width: 14%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;

    }



    .contenedor__slider,
    .slider {
        height: 50vh;
    }










    .AYM__H1__TITLE.line {
        font-size: 2rem;
        max-width: 100%;
        text-align: center;
    }




    .AYM__H1__TITLE.resaltar.f,
    .AYM__SPAN__CONTENEDOR__TITLE,
    .SPAN__CONECTORES {
        font-size: 1.6rem;
    }






    .AYM__CONTRATISTAS__CONTACTOBARRA {

        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: start;
        align-items: start;
        grid-gap: 10px;
        width: 90%;
        padding-bottom: 14px;
        right: 0;
        margin: 0 auto;
        white-space: nowrap;
        z-index: 99999;
        font-size: 1rem;
        font-weight: bold;
        background: linear-gradient(90deg, rgb(4, 141, 130) 0%, rgb(4, 141, 130) 80%, rgb(89, 206, 178) 100%);
        max-width: 90%;

    }




    .slide-content h2 {
        font-size: 2rem;
    }

    .slide-content p {
        font-size: 1.2rem;
    }


    .slide-content a {
        font-size: 1rem;
    }












    .AYMH1 {
        font-size: 2rem;
        width: 70%;
        margin: 0 auto;
        padding-bottom: 1rem;
    }

    .AYMH1__P,
    .AYMH1__span {
        font-size: 1.6rem;
    }


    .F4-h3 {
        font-size: 1.1rem;
    }

    .goldP {

        font-size: 1.2rem;

    }



    .BLOQUEAYM__H2,
    .BLOQUEAYM__H3 {
        font-size: 2rem;
    }

    .BLOQUEAYM__P.SUBTITLE__AYM {
        font-size: 1.2rem;
        width: 100%;

    }

    .BLOQUEAYM__P {
        font-size: 1.2rem;
    }


    .SECTION__DIAGNOSTICO__DIV1 h2,
    .SECTION__DIAGNOSTICO__DIV2 h2,
    .SECTION__DIAGNOSTICO__DIV3 h2,
    .formularioh2 {
        font-size: 2rem;
    }

    .SECTION__DIAGNOSTICO__DIV1 p,
    .SECTION__DIAGNOSTICO__DIV2 p {
        font-size: 1.2rem;
    }


    .SECTION__DIAGNOSTICO__DIV1 {
        height: 50vh;
    }


    .submitb {
        font-size: 1rem;
    }

    textarea.mensaje-textarea {
        font-size: 1rem;
    }




}





@media only screen and (max-width: 550px) {

    .footer__AYM__HOME {
        padding-top: 3rem;
        display: block;
        list-style: none;

    }

    .footer__AYM__HOME li {
        text-decoration: none;
        color: #636363;
        font-size: 14px;
        margin-bottom: 10px;
        list-style: none;
    }

    .footer__AYM__HOME li a {
        margin-bottom: 5px;
        font-size: 14px;
        text-decoration: none;
        color: #636363;
    }

    .footer__AYM__HOME li a:hover {
        text-decoration: underline;
        color: #242323;
    }



    .AYM__CONTRATISTAS__HOME {
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        min-width: 100%;
        background-color: #ffffff;
        margin-right: 0px;
        margin: 0 auto;
        z-index: 80;
    }


    .AYM__CONTRATISTAS__SECTION__HOME {
        background: linear-gradient(90deg, rgb(4, 141, 130) 0%, rgb(4, 141, 130) 90%, rgb(89, 206, 178) 100%);
        max-width: 100%;
        margin: 0 auto;
        height: 100vh;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .AYM__H1__TITLE.line {
        font-size: 24px;
        margin: 0;
        padding: 0;

    }



    .AYM__H1__TITLE.resaltar.f,
    .AYM__SPAN__CONTENEDOR__TITLE,
    .SPAN__CONECTORES {
        font-size: 18px;
    }



    .AYM__IMG__CONTENEDOR__TITLE {
        width: 15%;
        height: 15%;
    }



    .AYM__IMG__CONTENEDOR {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

    }

    .AYM__IMG__LOGOAYM.principal,
    .AYM__IMG__ENGRANAJES__LOGOAYM {
        width: 20%;
        height: auto;

    }



    .ISOIMG {
        height: auto;
        width: 17%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;

    }


    .AYM__IMG__SUBCONTENEDOR {
        padding-bottom: 21vh;
    }



    .AYM__CONTRATISTAS__CONTACTOBARRA {
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        align-items: center;
        grid-gap: 10px;
        width: 100%;
        padding-bottom: 14px;
        right: 0;
        margin: 0 auto;
        white-space: nowrap;
        z-index: 99999;
        font-size: 1rem;
        font-weight: bold;
        background: linear-gradient(90deg, rgb(4, 141, 130) 0%, rgb(4, 141, 130) 90%, rgb(89, 206, 178) 100%);
        max-width: 100%;
    }


    .AYM__CONTRATISTAS__CONTACTOBARRA span:first-child {
        justify-self: start;
        margin-left: 20px;
    }

    .AYM__CONTRATISTAS__CONTACTOBARRA span:last-child {
        justify-self: start;
        margin-left: 20px;
    }




    .AYM__CONTRATISTAS__DIV__slide {

        grid-column: 2;
        max-width: 100%;
        align-items: flex-end;
        display: flex;
        justify-content: center;
        align-items: center;


        padding: 0;
        margin: 0;

    }

    .contenedor__slider {
        grid-column: 1fr;
        height: 60vh;
        width: 100%;
        max-width: 100%;
        border-radius: 0rem;
    }

    .slider {
        width: 100%;
        height: 60vh;
    }



    .slide {
        display: none;
        align-items: center;
        justify-content: center;
    }

    .fade {
        opacity: 1;
        animation: fade 0.666s linear;
    }


    .slide.active {
        display: grid;

    }




    .slide-content {
        text-align: center;
        width: 100%;
    }

    .slide-content h2 {

        font-size: 24px;
        color: white;
        width: 80%;

    }

    .slide-content p {

        font-size: 18px;
        line-height: 1.1;
        width: 80%;
        margin: 0 auto;
        font-weight: 600;
        text-align: center;
        padding: 1rem 0;
        color: #ffffff;
        text-shadow: 2px 2px 2px rgba(66, 66, 66, 0.666);
    }


    .slide-content a {
        font-size: 14px;
        font-family: 'Georgia, serif';
    }


    .btn {
        background-color: var(--FONDO-Verde);
        color: var(--TEXTO-Azul);
        border: none;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        margin-top: 10px;
        text-transform: none;
        transition: 0.666s;
        border-radius: var(--Border-radius);
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);

    }

    .btn:hover {
        background-color: rgb(29, 1, 130);
        color: white;
        cursor: url('../cur/Mgold3.ico'), auto;
        transform: scale(1.03, 1.03);
    }

    .slider>.slide {
        background-blend-mode: soft-light;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 30%;
    }


    .slider__arrow__contenedor {
        position: relative;
    }


    .slider-arrow {
        position: absolute;
        top: -10vh;
        width: 6%;
        height: 6%;
        display: flex;
        background-color: transparent;
        border: none;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transform: translateY(-50%);
        cursor: url('../cur/Mblue3.ico'), auto;

    }




    .AYM__CONTRATISTAS__SECTION__SERVICIOS {
        max-width: 100%;
        height: auto;
    }


    .AYM__CONTRATISTAS__CONTENEDOR__TUERCAS .F4-img {
        height: auto;
        width: 50%;
        transition: transform 0.2s ease-in-out;
    }






    .AYM__CONTRATISTAS__SECTION__CONOCENOS {

        display: grid;
        grid-template-columns: 1fr;
        max-width: 100%;
        height: auto;
        align-items: center;
        justify-items: center;
        place-items: center;
        padding: 0;
        margin: 0 auto;
        padding-top: 2rem;
        padding-bottom: 2rem;
        background-color: #104764;
    }

    .bloqueaym {
        grid-column: 1fr;
        background-color: #052b58;
        height: auto;
        width: 95%;
        max-width: 1200px;
        border-radius: 0.6rem;
        margin: 0 auto;
        align-items: center;
        align-self: center;

    }







    .BLOQUEAYM__H3 {
        font-size: 24px;
    }

    .BLOQUEAYM__P.SUBTITLE__AYM {
        max-width: 90%;
        font-size: 16px;
    }

    .BLOQUEAYM__P {
        font-size: 14px;
    }






    .AYM__CONTRATISTAS__SECTION__VIDEO {
        max-width: 100%;
        height: auto;
    }









    .AYMH1 {
        font-size: 1.2rem;
        width: 70%;
        display: block;
        margin: 0 auto;
        margin-top: 1rem;
        padding-bottom: 1rem;
    }

    .AYMH1__P,
    .AYMH1__span {
        font-size: 1rem;
    }


    .F4-h3 {
        font-size: 12px;
    }

    .goldP {

        font-size: 1rem;

    }



    .BLOQUEAYM__H2,
    .BLOQUEAYM__H3 {
        font-size: 1.2rem;
    }

    .BLOQUEAYM__P.SUBTITLE__AYM {
        font-size: 1rem;
        width: 100%;

    }

    .BLOQUEAYM__P {
        font-size: 1rem;
    }


    .SECTION__DIAGNOSTICO__DIV1 h2,
    .SECTION__DIAGNOSTICO__DIV2 h2,
    .SECTION__DIAGNOSTICO__DIV3 h2,
    .formularioh2 {
        font-size: 1.2rem;
    }

    .SECTION__DIAGNOSTICO__DIV1 p,
    .SECTION__DIAGNOSTICO__DIV2 p {
        font-size: 1rem;
    }


    .SECTION__DIAGNOSTICO__DIV1 {
        height: 50vh;
    }



    .AYM__CONTRATISTAS__SECTION__DIAGNOSTICO {
        max-width: 100%;
        height: auto;
    }

    .AYM__CONTRATISTAS__SECTION__DIAGNOSTICO__CONTENEDOR {
        height: auto;
        width: 90%;
        display: block;
        margin: 0 auto;
        max-width: 1200px;
        border-radius: 0rem;

    }

    .SECTION__DIAGNOSTICO__DIV1 {
        height: 70vh;
        background-image: linear-gradient(15deg, #01015c, #027a81), url('../recursos/JPG/FONDO__OFICINA__AYM.webp');
        background-blend-mode: hard-light;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100% 100%;
        border-radius: 0.5rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;



    }

    .SECTION__DIAGNOSTICO__DIV1 h2 {
        font-size: 24px;
        font-weight: 900;
        text-transform: uppercase;
        color: #fff;
        font-family: 'Alatsi';
        text-shadow: 1.2px 1.2px 1.2px rgb(10, 1, 63);
        margin: 0 auto;
        background-image: linear-gradient(to right, rgb(74, 193, 1) 66.666%, transparent 50%);
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: 80% 3px;
    }



    .SECTION__DIAGNOSTICO__DIV1 p {

        font-size: 16px;
        margin: 0 auto;
        color: white;
        font-family: 'Alatsi';
        text-align: center;

    }

    .SECTION__DIAGNOSTICO__DIV2 {
        background-color: #052b58;
        border-radius: 15px;
        margin-top: 1rem;
    }


    .SECTION__DIAGNOSTICO__DIV2 h2 {
        font-size: 24px;
        width: 80%;
        padding-top: 1rem;
    }


    .SECTION__DIAGNOSTICO__DIV2 p {
        font-size: 16px;
        font-weight: 300;
        text-align: center;
        margin: 0 auto;
        width: 90%;
    }

    .SECTION__DIAGNOSTICO__DIV3 h2 {
        font-size: 24px;
        margin: 0 auto;
        color: white;
        font-family: 'Alatsi';
        text-align: center;
    }



    .SECTION__DIAGNOSTICO__DIV3 img {
        width: 50%;
        height: auto;
        display: block;
        margin: 0 auto;
    }














    .AYM__CONTRATISTAS__SECTION__CONTACTO {
        max-width: 100%;
        height: auto;
    }


    .AYM__CONTRATISTAS__SECTION__CONTACTO__CONTENEDOR {
        grid-column: 1fr;
        background-color: rgba(255, 255, 255, 0.788);
        height: auto;
        width: 90%;
        max-width: 600px;

    }


















    .submitb {
        font-size: 1rem;
    }

    textarea.mensaje-textarea {
        font-size: 1rem;
    }


    .AYM__CONTRATISTAS__SECTION__RUTAS {
        display: block;
        background-color: #ffffff;
        margin-bottom: 23vh;
        text-decoration: none;
    }


    .UL__AYM__RUTAS {
        padding-top: 2rem;
        list-style: none;
    }



    .UL__AYM__RUTAS li a,
    .UL__AYM__RUTAS li span {
        text-decoration: none;
        color: #0b00a4;
        cursor: pointer;
    }


    .UL__AYM__RUTAS ul li,
    .UL__AYM__RUTAS .SUB__UL li span {
        text-decoration: none;
        color: #0b00a4;
        cursor: pointer;
        list-style: none;
    }

}