.MENU__AYM {
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    width: 30%;

    height: 100vh;

    z-index: 100;
    margin-left: 10px;
    overflow-y: auto;
}


.MENU__AYM::-webkit-scrollbar {
    width: 6px;

}

.MENU__AYM::-webkit-scrollbar-thumb {
    background-color: rgb(3, 195, 6);
    border-radius: 4px;
}

.MENU__AYM::-webkit-scrollbar-track {
    background-color: #040248;
}



.NAV__LOGO {

    display: flex;
    align-items: center;
    justify-content: left;
    height: auto;

    width: 100%;
    margin: 0 auto;
    padding: 0;

    margin-top: 1rem;
    margin-bottom: 2rem;
}

.ICONO__MENU {
    height: 24px;
    width: 24px;
    margin-bottom: 6px;

}

#LogoAyMContratistas a {
    display: flex;
    margin-left: 20px;
    align-items: center;
    justify-content: left;
    text-decoration: none;
    color: black;
}

#LogoAyMContratistas img {
    display: inline-block;
    height: 50px;
    width: 50px;
}

#LogoAyMContratistas h1 {
    display: inline-block;
    margin-left: 10px;
    font-size: 1rem;
}


.NAV__AYM {
    width: 90%;
    height: auto;

    margin: 0 auto;
    margin-left: 30px;
    display: flex;
    justify-content: left;
    align-items: center;
}

.UL__AYM {
    list-style: none;
    font-size: 1.3rem;
    padding-bottom: 20vh;

}


.UL__AYM li {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    position: relative;
    display: block;

}

.UL__AYM li a,
.UL__AYM li span {
    text-decoration: none;
    color: #0b00a4;
    cursor: pointer;
}

.ICONO__MENU {
    vertical-align: middle;
    margin-right: 10px;
}

.SUB__UL {
    margin-left: 5px;
    display: block;
    font-size: 1rem;
}

.LI__LINK,
.LISTA__SUBUL {
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 25px;
}

.LI__LINK:hover,
.LISTA__SUBUL:hover {
    background-color: rgba(34, 255, 0, 0.139);
}

.SUB__UL li:hover {
    background-color: rgba(34, 255, 0, 0.139);
}


.footer__AYM {
    position: fixed;
    bottom: 0;
    left: 0;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    display: block;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: auto;
    background-color: #ffffff;
    margin: 0 auto;

    z-index: 999;
}

.footer__AYM .Mas__footer {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0 auto;
    width: 50%;
    cursor: pointer;
    height: auto;
    padding: 10px;
    z-index: 999;
    background: none;
    border: none;
}



.Mas__footer img {
    margin-right: 5px;

}

.menu__footer__AYM {
    display: none;
    position: absolute;
    bottom: 100%;

    display: block;
    justify-content: center;
    align-items: center;
    transform: translateY(10px);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    margin: 0 auto;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #dcdbdb;

}




.menu__footer__AYM.show__footer {
    display: block;
    transform: translateY(0);
    opacity: 1;

    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;


}

.menu__footer__AYM li {
    margin-bottom: 5px;
    font-size: 12px;
    text-decoration: none;
    color: #636363;
}

.menu__footer__AYM li a {
    margin-bottom: 5px;
    font-size: 12px;
    text-decoration: none;
    color: #636363;
}

.menu__footer__AYM li a:hover {
    text-decoration: underline;
    color: #353535;
}


.menu__footer__AYM.show__footer li {

    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 12px;
}





@media only screen and (max-width: 1000px) {
    .MENU__AYM {
        overflow-x: hidden;
    }

    .NAV__LOGO {

        display: flex;
        align-items: center;
        justify-content: left;
        height: auto;
        width: 100%;
        margin: 0 auto;
        padding: 0;

        margin-top: 1rem;
        margin-bottom: 2rem;

    }



    #LogoAyMContratistas .ICONO__LOGO__H1 {
        display: none;
    }

    .MENU__AYM {
        background-color: #ffffff;
        position: fixed;
        top: 0;
        left: 0;
        width: 90px;
        min-width: 90px;
        height: 100vh;
        z-index: 100;
        margin-left: 10px;

    }



    .UL__AYM li span {
        display: none;
    }



    .footer__AYM {
        width: 90px;
        margin-bottom: 3rem;

    }




}







@media only screen and (max-width: 550px) {

    .MENU__AYM {
        display: flex;

        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0 auto;
        margin-top: 85vh;
        height: 15vh;
        background-color: #ffffff;
        z-index: 9999;

        justify-content: center;
        align-items: center;

        overflow-y: hidden;

    }


    .footer__AYM {
        position: fixed;
        bottom: 0;
        right: 0;
        width: 20%;
        padding: 0;
        margin: 0 auto;
        display: none;

    }







    .NAV__LOGO {
        width: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        margin: 0 auto;
    }


    #LogoAyMContratistas .ICONO__LOGO__H1 {
        display: flex;
        align-items: center;
        font-size: 12px;
        white-space: nowrap;
    }




    .UL__AYM {
        display: flex;
        margin: 0;
        padding: 0;
    }

    .UL__AYM .LI__LINK,
    .UL__AYM .LISTA__SUBUL {
        display: none;
    }

    .LI__LINK.home {
        display: flex;
        height: auto;
        margin: 0 auto;
    }





}