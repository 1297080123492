.section__articulo {

    height: auto;
    width: 100%;
    display: grid;
    place-items: center;



}

.div__articulo {
    justify-content: center;
    align-items: center;
}


.div__articulo.articulo01 {
    max-width: 600px;
    width: 90%;
    height: auto;
    padding: 1rem;
    margin: 10px 0;


}

.div__articulo.articulo02 {
    max-width: 600px;
    width: 90%;
    height: auto;
    background-color: green;
    padding: 1rem;
    margin: 10px 0;
}

.div__articulo.articulo03 {
    max-width: 600px;
    width: 90%;
    height: auto;
    background-color: blue;
    padding: 1rem;
    margin-bottom: 2rem;
    margin: 10px 0;
}



.articulo__h2 {
    font-size: 2rem;
    font-weight: 900;
    color: #104764;
    font-family: 'Alatsi';
    text-shadow: 1px 1px 1px rgb(10, 1, 63);
    margin: 0 auto;
    text-align: center;
    width: 90%;
    padding-bottom: 2rem;

}

.articulo__h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #104764;
    font-family: 'Open Sans', sans-serif;

    margin: 0 auto;
    text-align: left;
    width: 100%;
    padding-bottom: 1rem;
}

.articulo__fecha {
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #212e35;
    font-family: 'Open Sans', sans-serif;
    margin: 0 auto;
    text-align: left;
    width: 100%;
    padding-bottom: 1rem;
}


.articulo__p {
    font-size: 1.3rem;
    font-weight: 500;
    color: #212e35;
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    width: 100%;
    padding-bottom: 1rem;
}


.articulo__a {
    text-decoration: none;
    color: rgb(1, 1, 164);
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
}


.articulo__img {
    display: block;
    width: 300px;
    height: 300px;
    margin: 0 auto;

}

@media only screen and (max-width: 550px) {


    .articulo__h2 {
        font-size: 20px;
        text-shadow: none;
    }

    .articulo__h3 {
        font-size: 18px;
    }

    .articulo__fecha {
        font-size: 12px;
        font-weight: 500;
        text-transform: none;

    }


    .articulo__p {
        font-size: 16px;
    }












    .articulo__img {
        display: block;
        width: 50%;
        height: auto;
        margin: 0 auto;
        padding-bottom: 1rem;

    }
}