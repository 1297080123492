@font-face {
  font-family: 'Alatsi';
  src: url('./fonts/Alatsi-Regular.ttf') format('truetype');
  font-display: fallback;
}

@supports not (font-display: fallback) {
  @font-face {
    font-family: 'Alatsi';
    src: url('./fonts/Alatsi-Regular.ttf') format('truetype');
    font-display: swap;
  }
}

body {
  min-width: 300px;
}

html {
  font-size: 16px;
  padding: 0;
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}




/*******************COLORES A&M CONTRATISTAS SAC**************************/


:root {
  --primary-color: linear-gradient(90deg, #02b6b9, #023d6b);
  --secondary-color: linear-gradient(90deg, #007986, #0a0141);
  --background-color: var(--secondary-color);

  --tituloAYM: #273b49;
  --fondoAYM: #eaf6f8;
  --pAYM: #063251;
  --aAYM: #252e69;
  --fonth1AYM: 2rem;
  --fonth2AYM: 1.5693rem;
  --fonth3AYM: 1.1666rem;







  --TITLE-Azul: #0004ff;
  /*----------------------    var(--TITLE-Azul);    ----*/

  --TITLE-Verde: #07e32c;
  /*--- --------------------   var(--TITLE-Verde);    ----*/

  --TEXTO-Azul: #09033a;
  /*--- ------------------   var(--TEXTO-Azul);    ----*/
  --FONDO-Verde: #5fe73d;
  /*--- ------------------   var(--FONDO-Verde);    ----*/






  --Border-radius: 0.26rem;
  /*--- ------------------   var(--Border-radius);    ----*/
}






/****************ANIMACIONES A&M CONTRATISTAS SAC**************************/
@-webkit-keyframes cambio-color {
  0% {
    color: blue;
  }

  33% {
    color: green;
  }

  66% {
    color: turquoise;
  }

  100% {
    color: blue;
  }
}

@-moz-keyframes cambio-color {
  0% {
    color: blue;
  }

  33% {
    color: green;
  }

  66% {
    color: turquoise;
  }

  100% {
    color: blue;
  }
}

@keyframes cambio-color {
  0% {
    color: blue;
  }

  33% {
    color: green;
  }

  66% {
    color: turquoise;
  }

  100% {
    color: blue;
  }
}

@keyframes cambio-color {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}


@keyframes DOWNTITLE {
  0% {
    opacity: 0;
    transform: translateY(-16px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.11);
  }

  100% {
    transform: scale(1);
  }
}




@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



@keyframes spin2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes elevador {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: scale(1.0369);
    transform: translateY(-6.666px)
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}


@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0% {
    transform: translate(0, 0) rotate(0);
  }

  10% {
    transform: translate(-3px, 0) rotate(-3deg);
  }

  20% {
    transform: translate(3px, 0) rotate(3deg);
  }

  30% {
    transform: translate(-3px, 0) rotate(-3deg);
  }

  40% {
    transform: translate(3px, 0) rotate(3deg);
  }

  50% {
    transform: translate(-3px, 0) rotate(-3deg);
  }

  60% {
    transform: translate(3px, 0) rotate(3deg);
  }

  70% {
    transform: translate(-3px, 0) rotate(-3deg);
  }

  80% {
    transform: translate(3px, 0) rotate(3deg);
  }

  90% {
    transform: translate(-3px, 0) rotate(-3deg);
  }

  100% {
    transform: translate(0, 0) rotate(0);
  }
}




@keyframes mover-mensaje {
  0% {
    transform: translateX(-100%);

  }

  50% {
    transform: translateX(100%);

  }

  100% {
    transform: translateX(35%);

  }
}

@keyframes salto {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}