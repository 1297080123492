.contacto__contenedor {
    height: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 68%;
    background-color: #ffffff;
    margin-right: 10px;
    z-index: 80;
    padding-top: 6rem;

}

.contacto__AYM__contenedor {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
    height: auto;

}

.contacto__box {
    text-align: center;
    width: 100%;
}

.contacto__AYM__contenedor__box1 {
    background-color: #ccc;



}

.contacto__AYM__contenedor__box1>div:first-child {
    background-color: #0e0079a8;
    background-image: url('../../aym666/galery/baners/CONTACTO-AYM.webp');
    background-blend-mode: soft-light;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    width: 100%;
    height: 70vh;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: left;
    align-items: center;
    grid-template-rows: 1fr auto;
    text-align: left;


}

.contacto__AYM__contenedor__box1>div:first-child h2 {
    font-size: 35px;
    margin-left: 5%;
    font-weight: bold;
    color: #fff;
    text-shadow: 1px 1px 2px rgb(16, 0, 63);
    font-family: 'Alatsi';
    filter: drop-shadow(0.1px 0.1px 1px rgba(0, 167, 11, 0.776));
    width: 90%;
}

.contacto__AYM__contenedor__box1>div:first-child div {
    background-color: rgba(30, 30, 30, 0.66);
    width: 100%;
    padding: 1rem 0;
    box-shadow: inset 0 -5px 0px -1px rgb(108, 158, 166);
    padding-bottom: 15vh;

}

.contacto__AYM__contenedor__box1>div:first-child div p {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-left: 20px;
    width: 80%;
    padding: 45px 0px;
    line-height: 1.5;


}



.contacto__AYM__contenedor__box2 {
    background-color: #104764;
    width: 100%;
    padding-bottom: 2rem;
    padding-top: 1.5rem;
    box-shadow: inset 0 -5px 0px -1px rgb(108, 158, 166);
}


.contacto__AYM__contenedor__box2>div:first-child {

    width: 70%;
    margin: 0 auto;
    height: auto;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-content: center;
    grid-gap: 30px;
    grid-column-gap: 30px;
    padding: 2rem 0;
    place-items: center;

    padding-bottom: 2rem;

}




.subbox {
    height: auto;
    padding: 1rem 0;
}

.subbox .contacto__AYM__img {

    height: 210px;
    width: auto;
}


.btn__contacto__AYM {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    border-radius: 6px;
    background-color: rgb(0, 199, 183);
    text-shadow: 1px 1px 1px rgb(0, 11, 68);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.436);
    transition: all 0.3s ease-in-out;
    padding: 10px;
    text-decoration: none;
}

.btn__contacto__AYM:hover {
    background-color: rgb(3, 158, 145);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.Reterno__links__contacto {
    width: 100%;
    height: 4rem;
    margin-bottom: 1rem;
    display: flex;
    align-self: center;
    align-items: center;

}

.Reterno__links__contacto a {
    text-decoration: none;
    color: #000000;
}


.Reterno__links__contacto a:hover {
    color: blue;
}

.Reterno__links__contacto a:first-child {
    margin-left: 20px;
}

.Reterno__links__contacto img {

    height: 24px;
    width: 24px;
    margin-right: 10px;
    display: flex;
    align-items: center;

    filter: brightness(1.2) saturate(10000%) invert(60%) sepia(100%) saturate(5836%) hue-rotate(90deg) brightness(93%) contrast(95%);
}


@media only screen and (max-width: 750px) {
    .contacto__contenedor {
        padding-top: 0;
    }


    .retorno__trabajaconnosotros {

        height: 3rem;
        margin-bottom: 0;
    }

    .contacto__AYM__contenedor__box1>div:first-child {

        height: 80vh;
    }
}



@media only screen and (max-width: 550px) {


    .contacto__contenedor {
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        min-width: 100%;
        background-color: #ffffff;
        margin-right: 0px;
        z-index: 80;
        padding-top: 2rem;

    }

    .contacto__AYM__contenedor {

        width: 100%;
        grid-template-columns: 1fr;
        justify-items: center;
        align-items: center;
        height: auto;
        margin: 0 auto;
        display: block;
        padding-bottom: 10vh;

    }








}