.section__atencionalcliente {
    height: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 73%;
    background-color: #ffffff;
    margin-right: 10px;
    z-index: 80;
    padding-top: 6rem;
}


.div__atencionalcliente {
    width: 100%;
    max-width: 1200px;
    height: auto;
    font-size: 1.2rem;
}

.retorno__atencionalcliente {
    width: 100%;
    height: 4rem;
    margin-bottom: 1rem;
    display: flex;
    align-self: center;
    align-items: center;


}

.retorno__atencionalcliente a {
    text-decoration: none;
    color: #000000;
}

.retorno__atencionalcliente a:hover {
    color: blue;
}


.retorno__atencionalcliente a:first-child {
    margin-left: 20px;
}

.retorno__atencionalcliente span {
    color: #636161;
    font-weight: bold;
}

.retorno__atencionalcliente img {
    height: 24px;
    width: 24px;
    margin-right: 10px;
    display: flex;
    align-items: center;

    filter: brightness(1.2) saturate(10000%) invert(60%) sepia(100%) saturate(5836%) hue-rotate(90deg) brightness(93%) contrast(95%);
}




.subcontacto__div__box3 {
    max-width: 976px;
    width: 100%;
    margin: 0 auto;
    line-height: 1.4;
    height: auto;
    margin: 0 auto;
    background-color: #fff;

}

.subcontacto__div__box3 h2 {
    font-family: Georgia, serif;
    font-size: 2rem;
    font-weight: 400;
    margin: .8em 0;
    width: 90%;

    padding-bottom: 0.1rem;
    text-align: left;
    margin: 0 auto;
}


[class*="subcontacto__div__box3__h2"]::after {
    content: "";
    display: block;
    width: 66.666px;
    height: 4px;
    margin: .39666em 0 .666em;
    background: #23ab08;
}

.subcontacto__div__box3>div p {
    text-align: left;
    margin: 0 auto;
    width: 90%;
    font-size: 1rem;
    font-weight: 300;
    font-style: italic;
    color: rgb(98, 98, 98);
}


.subcontacto__div__box3>p {
    text-align: left;
    line-height: 1.4;
    list-style: none;
    font-family: Arial, sans-serif;
    font-weight: 400;
    width: 90%;
    color: #1a1a1d;
    margin: 0 auto;
}

.subcontacto__div__box3__info {
    width: 100%;

}

.subcontacto__div__box3__info ul {
    list-style: none;
    text-align: left;
    width: 90%;
    margin: 0 auto;
    padding: 3rem 0;
}

.subcontacto__div__box3__info ul li a {
    text-decoration: none;
    color: #002a86;
}

.subcontacto__div__box3__info ul li a:hover {
    text-decoration: underline;
}

.subcontacto__div__box3__info ul li a img {
    height: 20px;
    margin-right: 10px;
}



.div__politica_privacidad {
    height: 2.5rem;
    display: grid;
    width: 100%;
    place-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    align-items: center;
    justify-items: center;


}

.div__politica_privacidad a {
    color: rgb(1, 62, 18);
    margin: 0 auto;
    width: 90%;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    text-align: left;
}

.div__politica_privacidad a:hover {

    text-decoration: underline;
}

.subcontacto__section__contenedor {
    background: red;
    padding: 0;
    margin: 0;
}





@media only screen and (max-width: 750px) {
    .section__atencionalcliente {
        padding-top: 0;
    }


    .retorno__atencionalcliente {

        height: 3rem;
        margin-bottom: 0;
    }
}


@media only screen and (max-width: 550px) {

    .section__atencionalcliente {
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        min-width: 100%;
        background-color: #ffffff;
        margin-right: 0px;
        z-index: 80;
        padding-top: 2rem;
    }


    .div__atencionalcliente {
        width: 100%;
        grid-template-columns: 1fr;
        justify-items: center;
        align-items: center;
        height: auto;
        margin: 0 auto;
        display: block;
        padding-bottom: 10vh;
    }


    .retorno__atencionalcliente {
        font-size: 14px;
    }


    .retorno__atencionalcliente img {
        height: auto;
        width: 3%;
        margin-left: 5px;
    }


}