.section__proyectosAYM {
    height: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 73%;
    background-color: #ffffff;

    z-index: 80;
    padding-top: 3rem;
    padding-bottom: 11vh;
    gap: 20px;
    justify-items: center;
    align-items: center;

    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.proyectos-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    max-width: 900px;
    /* Ajusta este valor según tus necesidades */
    margin: 0 auto;
    padding-bottom: 16vh;
}



.proyecto {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    justify-items: center;
    align-items: center;
}


.proyecto-img {
    width: 300px;
    height: 300px;
    transition: transform 0.3s ease;
}

.proyecto:hover .proyecto-img {
    transform: scale(1.1);
}

.proyecto-title {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 10px;
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 90%;
    height: 50px;
}




.image-viewer {
    /* Estilos para el visor de imágenes */
    position: fixed;
    top: 0;
    right: 0;
    width: 73%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.image-viewer-content {
    /* Estilos para el contenido del visor de imágenes */
    position: relative;
}

.image-viewer img {
    /* Estilos para la imagen en el visor de imágenes */
    max-height: 80vh;
    max-width: 80vw;
    transform: scale(1.5);
    object-fit: contain;
    z-index: 9999999;
}

.image-viewer-close {
    /* Estilos para el botón de cerrar */
    position: relative;
    top: 0;
    right: 0;
    padding: 0;
    background-color: #fff;
    color: #000;
    border: none;
    cursor: pointer;
}



@media only screen and (max-width: 1000px) {
    .section__proyectosAYM {
        width: 80%;
    }



    .image-viewer {

        width: 85%;
    }
}


@media only screen and (max-width: 600px) {
    .section__proyectosAYM {
        width: 95%;
    }

    .image-viewer {

        width: 95%;
    }
}