.section__blog {
    height: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 73%;
    background-color: #ffffff;
    margin-right: 10px;
    z-index: 80;
}



@media only screen and (max-width: 550px) {

    .section__blog {
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        min-width: 100%;
        background-color: #ffffff;
        margin-right: 0px;
        z-index: 80;
        padding-bottom: 18vh;

    }

}