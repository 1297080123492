.whatsapp__flotante {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 5px;
    margin-top: 10px;
    z-index: 100;
    box-shadow: 0 6px 6px -6px rgba(15, 0, 67, 0.856);
    width: 60px;

}


.notif-counter {
    position: absolute;
    top: -10px;
    right: -6px;
    background-color: red;
    color: white;
    font-size: 12px;
    width: 12px;
    height: 12px;
    text-align: center;
    border-radius: 50%;
    padding-top: 1px;
    box-sizing: border-box;



}





.whatsapp__flotante .whatsapp__flotante {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: #25D366;
    margin: 10px;
    margin-left: 1.5%;
    margin-bottom: 2%;
    cursor: pointer;
    border: transparent;
    transition: transform 0.3s ease-in-out;
    z-index: 2;
}

.shake-animation {
    animation-name: shake;
    animation-duration: 0.8s;

}


.whatsapp__flotante button:hover {
    transform: scale(1.1);
}

.whatsapp__flotante button img {
    filter: brightness(100%) saturate(10000%) invert(100%) sepia(100%) saturate(5836%) hue-rotate(180deg) brightness(100%) contrast(200%);
    width: 25px;
    height: 25px;
}


.descripcion__icon__acordeon {
    z-index: 4;
}


.mensaje-bienvenida {
    z-index: 0;
}

.whatsapp__flotante button .mensaje-bienvenida {
    display: none;
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateX(-100%);
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    padding: 5px;
    animation: mover-mensaje 1s ease-in-out forwards;
}

.whatsapp__flotante button .mensaje-bienvenida span {
    font-family: Arial, sans-serif;
    font-weight: 200;
    font-size: 0.8rem;
    color: #565656;
}

.whatsapp__flotante button {
    position: relative;
    z-index: 2;
}




.whatsapp__flotante button:hover .mensaje-bienvenida {
    display: block;
    z-index: 1;

}

.whatsapp__flotante button:hover .notif-counter {
    display: none;

}


.close-button {
    position: absolute;
    top: -95px;
    right: 150px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.32);
    color: rgba(0, 0, 0, 0.114);
    font-weight: bold;
    font-size: 0.5rem;
    border: none;
    outline: none;
    cursor: pointer;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.183);
    z-index: 1000;
}


@media (max-width: 750px) {

    @keyframes mover-mensaje {
        0% {
            transform: translateX(100%);

        }

        50% {
            transform: translateX(-180%);

        }

        100% {
            transform: translateX(-105%);

        }
    }




    .whatsapp__flotante {
        position: fixed;
        bottom: 0;
        left: 60%;
        right: 0;

        margin-top: 10px;
        z-index: 999;
        box-shadow: 0 6px 6px -6px rgba(15, 0, 67, 0.856);
        display: none;

    }


    .whatsapp__flotante button .mensaje-bienvenida {
        display: none;
        white-space: nowrap;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateX(100%);
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 5px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
        padding: 5px;
        animation: mover-mensaje 1s ease-in-out forwards;
    }


    .close-button {
        position: absolute;
        top: -75px;
        left: 120px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.32);
        color: rgba(0, 0, 0, 0.114);
        font-weight: bold;
        font-size: 0.5rem;
        border: none;
        outline: none;
        cursor: pointer;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.183);
        z-index: 1000;
    }



}